import { DocumentInfo, DocumentType, DocumentList as _DocumentList, RedirectUrl, OfflineList } from '../types';

export namespace Document {
  export class DocumentLoad {
    static readonly type = '[Document] Load New Document';
    constructor(public id: string) {}
  }
  export class Clear {
    static readonly type = '[Document] Clear Document';
  }
  export class DocumentCreate {
    static readonly type = '[Document] Create New Document';
    constructor(public document: DocumentInfo, public type: DocumentType) {}
  }
  export class DocumentUpdate {
    static readonly type = '[Document] Update Document';
  }
  export namespace DocumentImageUpdate {
    export class SetSize {
      static readonly type = '[Document] Update Upload Images Size';
      constructor(public size: number) {}
    }
    export class UpdateUploadSize {
      static readonly type = '[Document] Update Uploaded Images Size';
      constructor(public payload: number) {}
    }
  }

  export namespace DocumentList {
    export class TryAgain {
      static readonly type = '[Document List] Try Again';
      constructor(public document: _DocumentList) {}
    }
    export class ClearErrorStatus {
      static readonly type = '[Document List] Clear Error Status';
      constructor(public document: _DocumentList) {}
    }
    export class Load {
      static readonly type = '[Document List] Load Document List';
      constructor(
        public documentList: Array<_DocumentList>,
        public type: DocumentType,
        public redirect: RedirectUrl,
        public offlineSelectedList: OfflineList
      ) {}
    }
    export class LoadMore {
      static readonly type = '[Document List] Load More Document List';
      constructor(public documentList: Array<_DocumentList>) {}
    }
    export class Update {
      static readonly type = '[Document List] Update Document List';
      constructor(public document: _DocumentList, public offlineUuid?: string) {}
    }
    export class Delete {
      static readonly type = '[Document List] Delete Document';
      constructor(public uuid: string) {}
    }
    export class RemoveOffline {
      static readonly type = '[Document List] Remove Offline Document ';
    }
  }
}
