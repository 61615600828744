import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TestNetworkStateActions, NetworkActions as _ } from './network.actions';

interface NetworkModel {
  connected: boolean;
  connectionType: string;
}
const defaults: NetworkModel = {
  connected: false,
  connectionType: null,
};
@State<NetworkModel>({
  name: 'network',
  defaults,
})
@Injectable()
export class NetworkState {
  constructor() {}
  @Selector()
  static isConnected(state: NetworkModel): boolean {
    return state.connected;
  }
  @Selector()
  static isOffline(state: NetworkModel): 'offline' | 'online' | undefined {
    return state.connected ? 'online' : 'offline';
  }

  @Action(_.Listen)
  public onNetworkConnectionListen(ctx: StateContext<NetworkModel>) {
    Network.addListener('networkStatusChange', ({ connected, connectionType }) => {
      ctx.patchState({
        connected,
        connectionType,
      });
    });
  }

  @Action(_.CheckConnection)
  public async onNetworkConnectionCheck(ctx: StateContext<NetworkModel>) {
    const status = await Network.getStatus();
    ctx.patchState({
      connected: status.connected,
      connectionType: status.connectionType,
    });
  }
  @Action(TestNetworkStateActions.SetConnection)
  public onSetConnection(ctx: StateContext<NetworkModel>, action: TestNetworkStateActions.SetConnection) {
    ctx.patchState({
      connected: action.isConnected,
    });
  }
}
