import { Injectable } from '@angular/core';
import { Permission, Role, User } from '../types';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { GET_Response } from 'app/shared/api/types';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private api: ApiService) {}
  public async getUsers(): Promise<Array<User>> {
    const { data } = await this.api._get<GET_Response<User>>('accounts');
    return data;
  }
  public async getUser(id: string): Promise<User> {
    return await this.api._get<User>(`account/${id}`);
  }
  public async createUser(data: User): Promise<User> {
    return await this.api._post<User>('account', data);
  }
  public async updateUser(id: string, data: User): Promise<boolean> {
    return await this.api._patch(`account/${id}`, data);
  }
  public async deleteUser(id: string): Promise<void> {
    return await this.api._delete(`account/${id}`);
  }
  public async getRole(id: string): Promise<Role> {
    return await this.api._get<Role>(`role/${id}`);
  }
  public async getRoles(): Promise<Array<Role>> {
    return await this.api._get<Array<Role>>('roles');
  }
  public async createRole(data: Role): Promise<Role> {
    return await this.api._post<Role>('role', data);
  }
  public async updateRole(id: string, data: Role): Promise<boolean> {
    return await this.api._patch(`role/${id}`, data);
  }
  public async deleteRole(id: string): Promise<void> {
    return await this.api._delete(`role/${id}`);
  }
  public async getPermissions(): Promise<Array<Permission>> {
    return await this.api._get<Array<Permission>>('permissions');
  }

  public createTreeId(user: User): User {
    user.tree_ids = user?.permissions?.map((permission: Permission) => permission.id);
    return user;
  }
}
