import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../auth/state/auth.state';
import { Auth } from '../auth/state/auth.actions';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate() {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    return isAuthenticated ? isAuthenticated : this.store.dispatch(new Auth.LoginRedirect());
  }
}
@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate() {
    const isRole = this.store.selectSnapshot(AuthState.type);
    return isRole === 'Admin' ? true : false;
  }
}

@Injectable()
export class LinkGuard implements CanActivate {
  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  canActivate() {
    // var isAuthenticated = this.validateLink()
    // console.log(isAuthenticated)
    return true;
  }
}
