import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { DocumentService } from '../services/document.service';
import { Document, DocumentList, RedirectUrl, UploadImageStatusEnum } from '../types';
import { Document as _ } from './document.actions';
import { Router } from '@angular/router';
import { OfflineStorageState } from 'app/shared/offline/state/offline-storage.state';
import { OfflineStorage } from 'app/shared/offline/state/offline-storage.actions';
import { NetworkState } from 'app/shared/state/network/network.state';
import { LoaderService } from 'app/shared/services/loading/loader.service';
import { firstValueFrom } from 'rxjs';
import { PhotoUploadActions } from 'app/shared/services/upload/state/photo-upload.actions';

export class DocumentStateModel {
  public document: Document;
  public documentList: Array<DocumentList>;
  public isLoading: boolean;
  public redirect: RedirectUrl;
}

const defaults: DocumentStateModel = {
  documentList: [],
  document: null,
  isLoading: false,
  redirect: null,
};

@State<DocumentStateModel>({
  name: 'document',
  defaults,
})
@Injectable()
export class DocumentState {
  constructor(
    private documentService: DocumentService,
    private router: Router,
    private store: Store,
    private loader: LoaderService
  ) {}

  @Selector()
  static documentList(state: DocumentStateModel): Array<DocumentList> {
    return state.documentList;
  }
  @Selector()
  static document(state: DocumentStateModel): Document | null {
    return state.document || null;
  }
  @Selector()
  static status(state: DocumentStateModel) {
    return state.document.info.sharedInfo.status;
  }

  @Selector()
  static isOffline(state: DocumentStateModel): boolean {
    return state.document.info.isOffline;
  }
  @Selector()
  static uploadImages(state: DocumentStateModel): boolean {
    return (
      state.document.info.uploadImageStatus.status === UploadImageStatusEnum.NOT_UPLOADED ||
      state.document.info.uploadImageStatus.status === UploadImageStatusEnum.OFFLINE
    );
  }
  @Selector()
  static uploadImagesProgress(state: DocumentStateModel): number {
    return state.document.info.uploadImageStatus.size / state.document.info.uploadImageStatus.uploaded;
  }

  @Selector()
  static isLoading(state: DocumentStateModel): boolean {
    return state.isLoading;
  }
  @Selector()
  static redirect(state: DocumentStateModel): RedirectUrl {
    return state.redirect;
  }

  @Action(_.DocumentCreate)
  async onDocumentCreate(ctx: StateContext<DocumentStateModel>, { document, type }: _.DocumentCreate) {
    ctx.patchState({ isLoading: true });
    const newDocument = await this.documentService.createDocument(document, type);
    if (!this.store.selectSnapshot(NetworkState.isConnected)) this.store.dispatch(new OfflineStorage.Add(newDocument));
    ctx.setState({
      ...ctx.getState(),
      documentList: [newDocument, ...ctx.getState().documentList],
      isLoading: false,
    });
    this.router.navigateByUrl(this.router.createUrlTree([this.router.url, newDocument.uuid]));
  }

  @Action(_.DocumentLoad)
  async onDocumentLoad(ctx: StateContext<DocumentStateModel>, { id }: _.DocumentLoad) {
    ctx.patchState({ isLoading: true });
    const document = await this.documentService.getDocument(id);
    ctx.setState({
      ...ctx.getState(),
      document,
      isLoading: false,
    });
  }
  @Action(_.Clear)
  onDocumentClear(ctx: StateContext<DocumentStateModel>) {
    ctx.patchState({
      document: null,
    });
  }

  @Action(_.DocumentImageUpdate.SetSize)
  onDocumentUploadImagesSize(ctx: StateContext<DocumentStateModel>, { size }: _.DocumentImageUpdate.SetSize) {
    ctx.patchState({
      ...ctx.getState(),
      document: {
        ...ctx.getState().document,
        info: {
          ...ctx.getState().document.info,
          uploadImageStatus: {
            ...ctx.getState().document.info.uploadImageStatus,
            size: size + ctx.getState().document.info.uploadImageStatus.size,
          },
        },
      },
    });
  }
  @Action(_.DocumentImageUpdate.UpdateUploadSize)
  onDocumentUpdateUploadImagesSize(
    ctx: StateContext<DocumentStateModel>,
    { payload }: _.DocumentImageUpdate.UpdateUploadSize
  ) {
    ctx.patchState({
      ...ctx.getState(),
      document: {
        ...ctx.getState().document,
        info: {
          ...ctx.getState().document?.info,
          uploadImageStatus: {
            ...ctx.getState().document?.info.uploadImageStatus,
            uploaded: payload + ctx.getState().document?.info.uploadImageStatus.uploaded,
          },
        },
      },
    });
    ctx.dispatch(new PhotoUploadActions.UploadedSize(payload));
  }

  @Action(_.DocumentUpdate)
  async onDocumentUpdate(ctx: StateContext<DocumentStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      isLoading: true,
    });
    const documentCopy = {
      ...state.document,
      info: {
        ...state.document.info,
      },
    };
    await this.documentService
      .editDocument(documentCopy)
      .then(() => {
        ctx.patchState({
          ...ctx.getState(),
          document: documentCopy,
          isLoading: false,
        });
      })
      .catch(error => {
        console.log(error);
        ctx.patchState({
          isLoading: false,
        });
      });
  }
  @Action(_.DocumentList.Load)
  onDocumentListLoad(
    ctx: StateContext<DocumentStateModel>,
    { documentList, type, redirect, offlineSelectedList }: _.DocumentList.Load
  ) {
    const offlineDocumentList = this.store
      .selectSnapshot(OfflineStorageState[offlineSelectedList])
      .filter(item => item.type === type);
    ctx.patchState({
      ...ctx.getState(),
      redirect,
      documentList: [...offlineDocumentList, ...documentList],
    });
  }
  @Action(_.DocumentList.LoadMore)
  onDocumentListLoadMore(ctx: StateContext<DocumentStateModel>, { documentList }: _.DocumentList.LoadMore) {
    ctx.patchState({
      ...ctx.getState(),
      documentList: [...ctx.getState().documentList, ...documentList],
    });
  }
  @Action(_.DocumentList.Update)
  onDocumentListUpdate(ctx: StateContext<DocumentStateModel>, { document, offlineUuid }: _.DocumentList.Update) {
    const list = ctx.getState().documentList;
    const index = list.findIndex(item => item.uuid === (offlineUuid !== undefined ? offlineUuid : document.uuid));
    list[index] = document;

    ctx.patchState({
      ...ctx.getState(),
      documentList: [...list],
    });
    if (offlineUuid) ctx.dispatch(new _.DocumentList.RemoveOffline());
  }
  @Action(_.DocumentList.Delete)
  onDocumentListDelete(ctx: StateContext<DocumentStateModel>, { uuid }: _.DocumentList.Delete) {
    const list = ctx.getState().documentList;
    const index = list.findIndex(item => item.uuid === uuid);
    list.splice(index, 1);
    ctx.patchState({
      ...ctx.getState(),
      documentList: [...list],
    });
  }
  @Action(_.DocumentList.RemoveOffline)
  onDocumentListRemoveOffline(ctx: StateContext<DocumentStateModel>) {
    const list = ctx.getState().documentList;
    const filteredList = list.filter(item => item.id !== undefined);
    ctx.patchState({
      ...ctx.getState(),
      documentList: [...filteredList],
    });
  }

  @Action(_.DocumentList.TryAgain)
  async onDocumentListTryAgain(ctx: StateContext<DocumentStateModel>, { document }: _.DocumentList.TryAgain) {
    await firstValueFrom(ctx.dispatch(new _.DocumentLoad(document.uuid)));
    await this.documentService.editDocument(ctx.getState().document);
  }
  @Action(_.DocumentList.ClearErrorStatus)
  async onDocumentListClearErrorStatus(
    ctx: StateContext<DocumentStateModel>,
    { document }: _.DocumentList.ClearErrorStatus
  ) {
    await firstValueFrom(ctx.dispatch(new _.DocumentLoad(document.uuid)));
    const _document = ctx.getState().document;
    _document.info.uploadImageStatus = {
      status: UploadImageStatusEnum.UPLOADED,
      size: 0,
      uploaded: 0,
    };
    await this.documentService.editDocument(_document);
  }
}
