import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { OfflineStorage } from 'app/shared/offline/state/offline-storage.actions';
import { AdminMenu, GlobalConstants } from 'app/shared/variables/global';
import { environment } from 'environments/environment';
import { Observable, Subscription, map, of } from 'rxjs';
import { AuthState } from '../../auth/state/auth.state';
import { RouterState } from '@ngxs/router-plugin';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit, OnDestroy {
  @Select(AuthState.isAuthenticated) auth$: Observable<boolean>;
  @Select(RouterState.url) url$: Observable<string>;
  public selectedTab;
  public version = `${environment.version}`;
  public production = environment.production;
  public authMenu = GlobalConstants.authMenu;
  public adminMenu: Array<AdminMenu>;
  public activeMenuTitle = '';
  private urlSubscription: Subscription;
  constructor(private store: Store) {
    this.url$.subscribe(url => {
      if (url === undefined) return;
      this.selectedTab = url.substring(1);
    });
  }
  ngOnInit() {
    this.adminMenu = this.getMenuItems();
  }
  ngOnDestroy() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }

  public route(menu: AdminMenu) {
    switch (menu.url) {
      case 'sync':
        this.store.dispatch(new OfflineStorage.Sync());
        break;
      default:
        this.activeMenuTitle = menu.name;
        this.store.dispatch(new OfflineStorage.Navigate(menu.url));
        break;
    }
  }
  private permissionCheck(permission: string): Observable<boolean> {
    return this.store.select(AuthState.permissions).pipe(map(permissions => permissions?.includes(permission)));
  }
  private getMenuItems(): Array<AdminMenu> {
    return [
      {
        name: 'Properties',
        url: 'admin/properties',
        icon: 'business',
        hasPermission: this.permissionCheck('G:Properties'),
      },
      {
        name: 'Report Feed',
        url: 'admin/report',
        icon: 'document-text',
        hasPermission: this.permissionCheck('G:Reports'),
      },
      {
        name: 'Assigned Work',
        url: 'admin/assigned-documents',
        icon: 'construct',
        hasPermission: of(true),
      },
      { name: 'Settings', url: 'admin/settings', icon: 'cog', hasPermission: of(true) },
    ];
  }
}
